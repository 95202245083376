<template>
    <div class="week-list-item">
        <table-th class="th_class">
            <div class="week-label">
                {{ data.label }}
            </div>
        </table-th>

        <div
            v-for="(item, index) of data.child"
            :key="index"
            class="week-item"
            :class="index % 2 == 1 ? 'active' : ''"
        >
            <div
                v-if="item.subjectName"
                class="week-item-content"
                @mouseover="operateBtnShow(item)"
                @mouseleave="operateBtnHide(item)"
                @click.stop="goToDetail(data, item)"
            >
                <div
                    class="item-operate"
                    v-show="item.operateShow"
                ></div>
                <div
                    class="item-content-multiLine"
                    :class="item.operateShow ? 'active' : ''"
                    v-if="
                        item.subjectName.length > 4 ||
                        item.teacherName.length > 3 ||
                        item.subjectName.length + item.teacherName.length > 6
                    "
                >
                    <div class="class-info">
                        <div class="label">
                            {{ item.subjectName }}
                        </div>
                    </div>
                    <div class="teachers-info">
                        <div
                            class="teachers"
                            v-if="type != '2'"
                        >
                            ({{ item.teacherName }})
                        </div>
                        <div
                            class="classname"
                            v-else
                        >
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="item.className"
                                placement="top-start"
                            >
                                <span> ({{ item.className }})</span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div
                    class="item-content"
                    :class="item.operateShow ? 'active' : ''"
                    v-else
                >
                    <div class="class-info">
                        <div
                            class="label"
                            @click.stop="goToDetail(data, item)"
                        >
                            {{ item.subjectName }}
                        </div>
                    </div>
                    <div class="teachers-info">
                        <div
                            class="teachers"
                            v-if="type != '2'"
                        >
                            ({{ item.teacherName }})
                        </div>
                        <div
                            class="classname"
                            v-else
                        >
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="item.className"
                                placement="top-start"
                            >
                                <span> ({{ item.className }})</span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-else
                class="add-course-schedule"
            >
                <span class="no-scheduling"></span>
            </div>
        </div>
    </div>
</template>
<script>
import TableTh from "./Sub/TableTh.vue";
import TeachingResearchCourseScheduleTempFilter from "@/filters/TeachingResearchCourseScheduleTemp.js";
export default {
    name: "WeekListItem",
    filters: TeachingResearchCourseScheduleTempFilter,
    components: {
        TableTh,
    },
    props: {
        data: Object,
        index: Number,
        isTourClass: String,
        courseList: Array,
        advanceTime: Number,
        type: String,
    },
    computed: {
        prohibitAdding() {
            return require("@/assets/images/prohibit-adding.png");
        },
        noContentMyCurriculum() {
            return require("@/assets/images/my-curriculum.png");
        },
    },
    methods: {
        showError(time) {
            return time._timestamp >= new Date().getTime();
        },
        goToDetail(data, item) {
            // console.log('data',data,'item',item);
            this.$eventDispatch("goToDetail", {
                data: data,
                item: item,
                index: this.index,
            });
        },
        operateBtnShow(item) {
            // console.log("显示隐藏",item.operateBtnShow)
            item.operateShow = true;
        },
        operateBtnHide(item) {
            item.operateShow = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.no_content {
    margin: auto;
    text-align: center;
    .no_title {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}
.week-list-item {
    .th_class{
        width: 171px;
    }
    .week-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
    }
    .week-item.active {
        background-color: #edf4fa;
    }

    .week-item {
        width: 172px;
        height: 106px;
        box-sizing: border-box;
        border-right: 1px solid #61afff;
        border-bottom: 1px solid #61afff;
        position: relative;
        background-color: #fff;

        .week-item-content {
            background-color: #fff;
            box-sizing: border-box;
            padding: 10px;
            width: 170px;
            height: 104px;
            .item-operate {
                height: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .class-patrol {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #9da2a6;
                cursor: pointer;
            }
            .class-patrol.current {
                color: #3c7fff;
            }
            .item-content-multiLine.active {
                margin-top: -4px;
            }
            .item-content-multiLine {
                margin-top: 16px;
                .class-info {
                    display: flex;
                    justify-content: center;
                    .label {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #363b40;
                        // cursor: pointer;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .teachers-info {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .teachers {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #6d7073;
                        text-align: center;
                        width: 100px;
                    }
                    .classname {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #6d7073;
                        width: 150px;
                        text-align: center;
                    }
                }
            }

            .item-content.active {
                margin-top: -4px;
            }
            .item-content {
                margin-top: 16px;

                .class-info {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .label {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #363b40;
                        cursor: pointer;
                    }
                }
                .teachers-info {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .teachers {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #6d7073;
                    }
                    .classname {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #6d7073;
                        width: 150px;
                        text-align: center;
                    }
                }
            }
        }
        .week-item-content:hover {
            box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transform: scale(1.001);
            position: relative;
            z-index: 9999999999;
        }
        .add-course-schedule {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .no-scheduling {
                width: 14px;
                height: 2px;
                background: #c8cacc;
                border-radius: 1px;
            }
        }
    }
}
@media screen and (max-width: 1440px) {
    .week-list-item {
        .th_class{
            width: 160px;
        }
        .week-label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            // color: #808487;
            color: #ffffff;
        }
        .week-item.active {
            background-color: #edf4fa;
        }

        .week-item {
            width: 160px;
            height: 80px;
            box-sizing: border-box;
            border-right: 1px solid #61afff;
            border-bottom: 1px solid #61afff;
            position: relative;
            background-color: #fff;

            .week-item-content {
                background-color: #fff;
                box-sizing: border-box;
                padding: 10px;
                width: 158px;
                height: 78px;
                .item-operate {
                    height: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .class-patrol {
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #9da2a6;
                    cursor: pointer;
                }
                .class-patrol.current {
                    color: #3c7fff;
                }
                .item-content-multiLine.active {
                    margin-top: -12px;
                }
                .item-content-multiLine {
                    margin-top: 8px;
                    .class-info {
                        display: flex;
                        justify-content: center;
                        .label {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #363b40;
                            // cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .teachers-info {
                        display: flex;
                        align-items: center;
                        .teachers {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6d7073;
                            width: 100px;
                        }
                        .classname {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6d7073;
                            width: 140px;
                            text-align: center;
                        }
                    }
                }

                .item-content.active {
                    margin-top: -12px;
                }
                .item-content {
                    margin-top: 8px;

                    .class-info {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .label {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #363b40;
                            cursor: pointer;
                        }
                    }
                    .teachers-info {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .teachers {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6d7073;
                        }
                        .classname {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #6d7073;
                            width: 140px;
                            text-align: center;
                        }
                    }
                }
            }
            .week-item-content:hover {
                box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transform: scale(1.001);
            }
            .add-course-schedule {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .no-scheduling {
                    width: 14px;
                    height: 2px;
                    background: #c8cacc;
                    border-radius: 1px;
                }
            }
        }
    }
}
</style>

<template>
    <div class="week-list-layout">
        
        <week-list-item
            v-for="(item, index) of weekList"
            :key="index"
            :data="item"
            :index="index"
            :type="type"
            :isTourClass="isTourClass"
            :courseList="courseList"
            :advanceTime="advanceTime"
            >
        </week-list-item>
        
    </div>
</template>
<script>
    import WeekListItem from './WeekListItem.vue';
    export default {
        name: "WeekList",
        components: {
            WeekListItem
        },
        props: {
            weekList: Array,
            isTourClass: String,
            courseList: Array,
            advanceTime: Number,
            type:String
        },
        
        computed: {
            noContentMyCurriculum() {
                return require("@/assets/images/my-curriculum.png");
            },
        },
        mounted(){
            // console.log("这是什么数据",this.weekList)
        }

    }
</script>
<style lang="scss" scoped>
    .week-list-layout {
        display: flex;
        border-top-left-radius: 8px;
        
    }
</style>

<template>
    <div>
        <div
            class="course-schedule-view"
            id="pdfPreviewDom1"
        >
            <CourseTableTitle
                :classAndTeacher="classAndTeacher"
                :type="type"
                class="course-table-title"
            ></CourseTableTitle>

            <div
                v-if="loding"
                class="course-schedule-view-content"
                style="position: relative"
            >
                <CourseList
                    :courseList="courseList"
                    :queryList="queryList"
                    :isTourClass="isTourClass"
                    :type="type"
                />
                <WeekList
                    v-if="queryList.dayWeek === 'week' && !getDataLoading"
                    :advanceTime="advanceTime"
                    :weekList="weekList"
                    :isTourClass="isTourClass"
                    :courseList="courseList"
                    :type="type"
                />
                <DayList
                    v-if="queryList.dayWeek === 'day' && !getDataLoading"
                    :dayList="dayList"
                    :tableData="tableData"
                    :isTourClass="isTourClass"
                    :courseList="courseList"
                    :type="type"
                    :courseScheduleDetailSubjectsList="
                        courseScheduleDetailSubjectsList
                    "
                    :courseScheduleDetailTeachersList="
                        courseScheduleDetailTeachersList
                    "
                    :queryList="queryList"
                />
                <div
                    v-if="queryList.dayWeek === 'week' && tableData.length <= 0"
                    style="
                        background-color: #fff;
                        position: absolute;
                        top: 46px;
                        left: 120px;
                        width: calc(100% - 120px);
                        height: calc(100% - 46px);
                    "
                >
                    <div class="no_content">
                        <img
                            :src="noContentMyCurriculum"
                            alt="暂无排课~"
                        />
                        <div class="no_title">暂无排课~</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CourseTableTitle from "./CourseScheduleView/CourseTableTitle.vue";
import CourseList from "./CourseScheduleView/CourseList.vue";
import WeekList from "./CourseScheduleView/WeekList.vue";
import DayList from "./CourseScheduleView/DayList.vue";

export default {
    name: "CourseScheduleView",
    components: {
        CourseList,
        WeekList,
        DayList,
        CourseTableTitle,
    },
    computed: {
        noContentMyCurriculum() {
            return require("@/assets/images/my-curriculum.png");
        },
    },
    props: {
        classAndTeacher: Object,
        queryList: Object,
        tableData: Array,
        courseList: Array,
        weekList: Array,
        dayList: Array,
        isTourClass: String,
        type: String,
        courseScheduleDetailSubjectsList: Array,
        courseScheduleDetailTeachersList: Array,
        getDataLoading: Boolean,
        loding: {
            default: true,
        },
        advanceTime: Number,
    },
};
</script>

<style lang="scss" scoped>
.course-schedule-view {
    max-width: 1340px;
    border: 1px solid #61afff;
    margin: auto;
    margin-top: 10px;
    overflow-x: scroll;
    .course-table-title {
        height: 48px;
        width: 100%;
    }
    .course-schedule-view-content {
        height: calc(100vh - 260px);
        overflow: auto;
        display: flex;
        background-color: #fafafa;
    }
    border-radius: 8px;
    .no_content {
        margin-top: 25%;
        text-align: center;
    }
    .no_title {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}
@media screen and (max-width: 1440px) {
    .course-schedule-view {
        max-width: 1260px;
        border: 1px solid #61afff;
        margin: auto;
        margin-top: 10px;
        overflow-x: scroll;
        .course-table-title {
            height: 48px;
            width: 100%;
        }
        .course-schedule-view-content {
            height: calc(100vh - 260px);
            overflow: auto;
            display: flex;
            background-color: #fafafa;
        }
        border-radius: 8px;
        .no_content {
            margin-top: 25%;
            text-align: center;
        }
        .no_title {
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
        }
    }
}
</style>
